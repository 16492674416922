import React, { useState, useEffect } from "react";
import Header from "../maincomponents/Header";
import Footer from "../maincomponents/Footer";
import { Link } from "react-router-dom";

import { fetchCategoryList, Contact } from "../utils/ApiService";
import ReactFlagsSelect from "react-flags-select";
import swal from "sweetalert";
import Spinner from "./main/Spinner";
import { Helmet } from "react-helmet";

const Home = () => {
  //------------------------CATEGORY LISTS---------------------------------

  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const data = await fetchCategoryList();
        setCategory(data);
        setLoading(false);
      } catch (error) {
        console.error("Error Fetching Category", error);
        setLoading(false);
      }
    };

    getCategoryList();
  }, []);
  useEffect(() => {
    const getCategoryList = async () => {
      try {
        const data = await fetchCategoryList();
        setCategory(data);
      } catch (error) {
        console.error("Error Fetching Category", error);
      }
    };

    getCategoryList();
  }, []);
  //------------------------GET A QUICK REPLY---------------------------------
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState("");

  const handleContact = async (event) => {
    event.preventDefault();
    const contactData = {
      name: name,
      message: message,
      country: selected,
      contact: contact,
      email: email,
    };

    try {
      const response = await Contact(contactData);
      swal(
        "Good job!",
        "Thank you for contacting us, we will connect to you shortly",
        "success"
      );
      setName("");
      setMessage("");
      setSelected("");
      setContact("");
      setEmail("");
    } catch (error) {
      // console.error('Error during user registration', error);
      swal("Oops!", "Please Login and try again.", "error");
    }
  };
  return (
    <>
      {loading && <Spinner />}
      <Header />
      <Helmet>
        <title>
          SAASKIN Corporation - Your Trusted Healthcare Partner || Home
        </title>
        <meta
          name="description"
          content="Your go-to source for the latest and best products from SAASKIN Corporation. Discover a wide range of high-quality products tailored to your needs."
        />
        <meta
          name="keywords"
          content="Abbott SD Bioline, Rapid Diagnostic Test Kit, Healthcare, Medical Supplies"
        />
        <link rel="canonical" href="https://saaskin.com" />

        <meta
          property="og:title"
          content="SAASKIN Corporation - Your Trusted Healthcare Partner"
        />
        <meta
          property="og:description"
          content="Your go-to source for the latest and best products from SAASKIN Corporation. Discover a wide range of high-quality products tailored to your needs."
        />
        <meta property="og:image" content="./images/saaskin_title_logo.png" />
        <meta property="og:url" content="https://saaskin.com" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {`{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "SAASKIN Corporation",
      "url": "https://saaskin.com",
      "logo": "./images/saaskin_title_logo.png",
      "sameAs": [
        "https://www.facebook.com/yourpage",
        "https://www.instagram.com/yourprofile",
        "https://www.linkedin.com/company/yourcompany",
        "https://twitter.com/yourprofile"
      ]
    }`}
        </script>
      </Helmet>

      <div className="container" style={{ paddingTop: "30px" }}>
        <h3 style={{ textAlign: "center" }}>Our Product Range</h3>
        <div className="row" style={{ paddingTop: "30px" }}>
          {category.map((cat, index) => (
            <div key={cat.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <Link
                to={`/subcategory/${cat.id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="card">
                  <img
                    className="card-img-top"
                    src={cat.product_image}
                    alt={cat.name}
                    style={{ height: "200px", objectFit: "contain" }}
                  />
                  <div className="card-body">
                    <p className="card-text" style={{ textAlign: "center" }}>
                      {cat.name}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div style={{ padding: "40px", fontWeight: "10px", fontSize: "18px" }}>
        <form onSubmit={handleContact}>
          <h1 style={{ textAlign: "center" }}>Get a Quick Reply</h1>
          <div className="row">
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Country</label>
              <div
                style={{
                  border: "1px solid",
                  height: "44px ",
                  borderRadius: "5px",
                }}
              >
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  searchable
                  searchPlaceholder="Search Countries"
                  placeholder="Select Your Country"
                />
              </div>
            </div>
            <div className="col-md-6" style={{ padding: "15px 30px" }}>
              <label>Mobile Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Valid Number"
                id="mobile"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div className="col-md-12" style={{ padding: "15px 30px" }}>
              <label>Message</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Message"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ border: "1px solid" }}
              />
            </div>
            <div
              className="col-md-12"
              style={{ textAlign: "center", padding: "15px 30px" }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%", backgroundColor: "#5bc0de" }}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Home;
